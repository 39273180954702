import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';

const API_USERS_URL = `${environment.apiUrl}`;

const TOKEN_HEADER_KEY = 'x-access-token';

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  // public methods
  login(username: BigInteger, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/sistema_gestion_academica/iniciarSesionAdmin`, { username, password });
  }

  recaptcha(captcha: any): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/captcha`, {captcha});
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/sistema_gestion_academica/forgot-password`, {
      email,
    });
  }

  getUserByToken(token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders().set(TOKEN_HEADER_KEY,token);
    return this.http.get<UserModel>(`${API_USERS_URL}/test/usuario`,{ headers: httpHeaders});
  }

  actualizarEstadoActive(av_cod_subplan: string, av_per_doc_num: string,active: number): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/estudiante/actualizarEstadoActive`, {av_cod_subplan,av_per_doc_num,active });
  }
  
}
